<template>
  <div class="animated fadeIn">

    <b-row>
      <b-col cols="12">

      <div class="card" >
        <div class="card-header">
          <strong>Export Data</strong>
        </div>

        <div class="card-body">

            <div class="row">
              <div class="col-4">
                <p class="font-weight-bold">
                  From date
                </p>

                <v-date-picker
                  mode='single'
                  v-model='start_date'
                  is-inline
                  show-caps>
                </v-date-picker>
                <p v-show="start_date">
                  {{ exportStartDate }}
                  <b-button @click="resetStartDate" variant="outline-danger" class="btn-sm mt-2 ml-3">Clear</b-button>
                </p>

              </div>

              <div class="col-4">
                <p class="font-weight-bold">
                  To date
                </p>

                <v-date-picker
                  mode='single'
                  v-model='end_date'
                  is-inline
                  show-caps>
                </v-date-picker>
                <p v-show="end_date">
                  {{ exportEndDate }}
                  <b-button @click="resetEndDate" variant="outline-danger" class="btn-sm mt-2 ml-3">Clear</b-button>
                </p>
              </div>

              <div class="col-4">
                <p class="font-weight-bold">
                  Export Type
                </p>
                <b-form-select
                  id="input-3"
                  v-model="type"
                  :options="exportoptions"
                ></b-form-select>
                <p class="font-weight-bold mt-3">
                  Export Format
                </p>
                <b-form-select
                  id="input-3"
                  v-model="format"
                  :options="exportformatoptions"
                ></b-form-select>
              </div>

            </div>

            <b-row v-if="type === 'custom_report'" class="mt-3">
              <b-col class="mt-3">
                
                <b-form-group  label="Custom Report Headings:" v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group
                    id="checkbox-group-1"
                    v-model="custom_report_headings"
                    :options="custom_heading_options"
                    :aria-describedby="ariaDescribedby"
                    name="flavour-1"
                  ></b-form-checkbox-group>
                </b-form-group>

              </b-col>
            </b-row>

        </div>
        <div class="card-footer">
          <b-button class="float-right" :disabled="!validExportFields" @click="downloadCSV" variant="outline-success">Export</b-button>
        </div>
      </div>

      </b-col>

    </b-row><!--/.row-->

  </div>

</template>

<script>
  import axios from '../../../shared/axios/auth-no-loader'
  import { setupCalendar, DatePicker } from 'v-calendar'
  import 'v-calendar/lib/v-calendar.min.css'
  import serve_download from '../../../main'

  setupCalendar({
    firstDayOfWeek: 2 // Monday
  })

  const today = new Date();
export default {
  components: {
      'v-date-picker': DatePicker
  },
  name: 'CustomerService',
  data: () => {
    return {
        get_document: false,
        start_date: null,
        end_date: null,
        type: '',
        format: '',
        custom_report_headings: [],
        exportoptions: [
          {value:'', text:'Please select'},
          /*{value:'sp_complaint_report_policy', text:'SP Complaint Report'},
          {value:'complaint_report', text:'Complaint Report'},*/
          {value:'closed_claim_report', text:'Closed Claim Report'},
          {value:'claim_float_claim_listing', text:'Claim Float Claim Listing Report - New Business'},
          {value:'claim_float_claim_listing_continuation', text:'Claim Float Claim Listing Report - Continuation'},
          /*{value:'claim_float_payment', text:'Claim Float Payment Report'},*/
          {value:'claims', text:'Ignite Daily Claim Feed'},
          {value:'payments_report', text:'Outgoing Payment Report'},
          {value:'ignite_errors', text:'Ignite Error Report'},
          /*{value:'custom', text:'Custom'},
          {value:'single', text:'Single'},*/
        ],
        custom_heading_options: [
          { text: 'Customer Data', value: 1 },
          { text: 'Pet Data', value: 2 },
          { text: 'Policy Data', value: 3 },
          { text: 'Claim Data', value: 4 }
        ],
        exportformatoptions: [
          {value:'', text:'Please select'},
          {value:'excel', text:'Excel (.xlsx)'},
          {value:'csv', text:'CSV (.csv)'},
        ],
    }
  },
  methods: {

      resetStartDate() {
        this.start_date = null
      },
      resetEndDate() {
        this.end_date = null
      },
      downloadCSV() {

        this.get_document = true;

        axios({
          url: '/reports/standard/?date_from=' + this.exportRangeStart + '&date_to=' + this.exportRangeEnd + '&report_type=' + this.type + '&file_format=' + this.format + '&custom_report_headings=' + this.custom_report_headings,
          method: 'GET',
          responseType: 'blob', // important
        }).then((response) => {

          serve_download(response);
          this.get_document = false;

        }).catch(error => {
              console.log(error)
              // Unauthorised
              if (error.response.status === 401) {
                this.$store.dispatch('logout');
              }
              console.log('post error');
              console.log(error.response);
          });

      }
  },
  computed: {

      exportStartDate: function () { return new Date(this.start_date).toLocaleDateString('en-GB') },
      exportEndDate: function () { return new Date(this.end_date).toLocaleDateString('en-GB') },
      exportRangeStart: function () {
        var date = new Date(this.start_date).toLocaleDateString('en-GB');

        var string_date = ''
        string_date += date.substring(6, 10);
        string_date += '-';
        string_date += date.substring(3, 5);
        string_date += '-';
        string_date += date.substring(0, 2);
        return string_date
      },
      exportRangeEnd: function () {
        var date = new Date(this.end_date).toLocaleDateString('en-GB');

        var string_date = ''
        string_date += date.substring(6, 10);
        string_date += '-';
        string_date += date.substring(3, 5);
        string_date += '-';
        string_date += date.substring(0, 2);
        return string_date
      },
      validExportFields: function(){
       return this.start_date && this.end_date;
      },
  }
}
</script>
